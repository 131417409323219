(function ($) {
  Drupal.behaviors.bannerBehavior = {
    attach: function(context, settings){
      $('.field--banner-image', context).once('banner', function(){
        var $banner = $(this);
        var $bannerContent = $(this).find('.field__items');
        var $slides = $(this).find('.field__item');

        $slides.first().clone().appendTo($bannerContent);
        var slideCount = $(this).find('.field__item').length;
        var currentIndex = 0;

        setInterval(nextSlide, 3000);

        function nextSlide() {
          currentIndex++;
          if(currentIndex >= slideCount) {
            currentIndex = 1;
            TweenMax.set($bannerContent, {x: "0%"});
          }
          TweenMax.to($bannerContent, 1, {x: -100 * currentIndex + '%', ease: Strong.easeInOut});
        }
      });
    }
  };

  Drupal.behaviors.specialCharactersBehavior = {
    attach: function(context, settings){
      $('h1, h2, h3').once('specialCharacters', function() {
        var splitText = $(this).text().split(' ');
        var splitTextFiltered = [];
        var i;

        for (i = 0; i < splitText.length; i++) {
          var str = splitText[i];
          if (i > 0 && str.length < 4) {
            splitTextFiltered[splitTextFiltered.length - 1] += ' ' + str;
          } else {
            splitTextFiltered.push(str);
          }
        }

        for (i = 0; i < splitTextFiltered.length; i++) {
          var str = splitTextFiltered[i];
          str = '<span>' + str + '</span>';
          splitTextFiltered[i] = str;
        }

        var fixedString = splitTextFiltered.join(' ');
        fixedString = fixedString.replace('&', '<span class="default-font">&</span>');

        if($(this).find('a').length) {
          $(this).find('a').html(fixedString);
        } else {
          $(this).html(fixedString);
        }
      });
    }
  };


  Drupal.behaviors.sidemenuSubBehavior = {
    attach: function(context, settings){
      $('.js-main-menu-sub', context).once('sidemenuSub', function(){
        var $menu = $(this);
        if($menu.find('.active-trail').length < 1) {
          return $menu.remove();
        }
        $menu.find('.menu-block-wrapper > .menu > li.active-trail').siblings('li').remove();
      });
    }
  };

Drupal.behaviors.tooltipsBehavior = {
  attach: function(context, settings){
    $('.vocabulary-category', context).once('tooltips', function(){
      var title = $(this).find('.field--title').text().trim();
      var $image = $(this).find('img');
      $image.attr('title', title);
      $image.tooltipsy();
    });
  }
};

Drupal.behaviors.downloadSCrollBehavior = {
  attach: function(context, settings){
    $('.js-downloads-scroll', context).once('downloadSCroll', function(){
      $(this).click(function(e) {
        e.preventDefault();
        TweenMax.to($(window), 1, {ease: Strong.easeInOut, scrollTo: {y: ($('.field--attachments').offset().top - 30)}})
      });
    });
  }
};


Drupal.behaviors.cookieComplianceBehavior = {
  attach: function(context, settings){
    $('.cookie-compliance', context).once('cookieCompliance', function(){
      var cookieComplianceValue = $.cookie('cookie_compliance');
      var $cookieCompliancePopup = $(this);

      if(cookieComplianceValue === 'ok') {
        $cookieCompliancePopup.hide(0);
      } else {
        $('a').on('click', function() {
          $.cookie('cookie_compliance', 'ok');
          $cookieCompliancePopup.fadeOut(300);
        });
      }
    });
  }
};



Drupal.behaviors.sideViewBehavior = {
  attach: function(context, settings){
    $('.node__title', context).once('sideView', function(){
      var text = $(this).text().trim();
      $(this).find('a').attr('title', text);
      if(text.length > 50) {
        text = text.replace(/^(.{50}[^\s]*).*/, "$1");
        text += '...';
      }

      $(this).find('a').text(text);
    });
  }
};

Drupal.behaviors.multiDateBehavior = {
  attach: function(context, settings){
    $('.node--event', context).once('multiDate', function(){
      var $node = $(this);
      if($(this).find('.date-display-end').length) {
        $node.addClass('has-end-date');
      }
    });
  }
};




})(jQuery);
